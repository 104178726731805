import * as React from 'react'
import styled from 'styled-components'

import Layout from '@components/Layout'
import { H1, H2, H3, H4, Card } from '@components/UI'

const MyH1 = styled(H1).attrs({
  p: 2
})``

const MyCard = styled(Card).attrs({
  p: 5,
  bg: 'dark'
})``

const MyCard2 = props => <Card p={5} bg="black" {...props} />

const Test = () => {
  return (
    <Layout title="Test">
      <MyH1>Hello I am MyH1</MyH1>
      <H1>Hello I am H1</H1>
      <H2>Hello I am H2</H2>
      <H3>Hello I am H3</H3>
      <H4>Hello I am H4</H4>
      <Card p={5} bg="primary" />
      <MyCard p={3} />
      <MyCard2 p={5} />
    </Layout>
  )
}

export default Test
